import { ProdCards, ProdOneOfOneShoe, ProdSkatedShoe } from 'components'
import { withClassNames } from '~/styles'
import s from './prod-tangibles.module.scss'
const cx = withClassNames(s)

export const ProdTangibles = () => {
  return (
    <div className={cx('prod-tangibles', 'container')}>
      <div>
        <h2>Unlock tangible collectibles through owning NFT&#39;s</h2>
        <p>
          Both shoes will be raffled off on July 23rd, and all tangible cards
          will be sent on August 1st
        </p>
      </div>

      <div className={cx('tangible-items')}>
        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <ProdCards type='prodLegendary' />
          </div>
          <h3 className='legendary-text'>Legendary Card</h3>
          <p>
            Signed and numbered, only 300 available.
            <br></br>
            This item will be unlocked by owning a{' '}
            <span className='legendary-text'>Legendary</span> NFT.
          </p>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <ProdCards type='prodLegendaryRefractor' />
          </div>
          <h3 className={cx('sweep', 'wp-control')}>
            Legendary Refractor Card
          </h3>
          <p>
            Signed, numbered, and a piece of P-Rod&#39;s shoe inside of the
            card, only 200 available.
            <br></br>
            This item will be unlocked by owning a{' '}
            <span className='sweep'>Legendary&nbsp;Refractor</span> NFT.
          </p>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <ProdOneOfOneShoe />
          </div>
          <h3>1/1 Sample Shoe</h3>
          <p>
            Truly a 1/1 sample, this is the only shoe in this specific colorway
            to ever exist.
            <br></br>
            Owners of a <span className='legendary-text'>
              Legendary
            </span> or <span className='sweep'>Legendary Refractor</span> NFT
            will be entered to win this 1/1 shoe.
          </p>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <ProdSkatedShoe />
          </div>
          <h3>Skated Prod Shoe</h3>
          <p>
            The shoe that Paul skated in his &#34;Nollie Frontside Nose Nollie
            Heel Out&#34; clip. Each time this NFT moment is pulled out of a
            pack, you will receive a raffle ticket for a chance to win this
            shoe.
          </p>
        </div>
      </div>
    </div>
  )
}
