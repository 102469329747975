import {
  BounceInOutAnimation,
  BuyButton,
  SlideInFromLeftAnimation,
  SlideInFromRightAnimation,
  ToreyNFTThumbnail,
} from 'components'
import { motion } from 'framer-motion'
import { trpc } from '~/api/client'
import { useAuth } from '~/auth/client'
import { useSelectedPackTypeDetails } from '~/client/contexts/SelectedPackTypeContext'
import { cx, PackType } from '~/client/Packs/PackType'
import { useDeviceSize } from '~/client/utils/ScreenSizes'
import { Countdown, MailChimpCountdown } from '../Countdown/CountDown'
import { useToreyPackDetails } from './hooks'
import { ToreyPackImage } from './ToreyPack'
import { ToreyTangibles } from './ToreyTangibles'
import { IfFeatureEnabled } from '@growthbook/growthbook-react'
import { FFlags } from '~/analytics/growthbook'
import { IfFeatureDisabled } from '~/analytics/growthbook/react'

export const ToreyPackType = () => (
  <PackType>
    <ToreyPackPurchaseDetails />
    <hr />
    <ToreyTangibles />
    <hr />
    <ToreyPackOdds />
    <hr />
    <ToreyFAQ />
  </PackType>
)
const ToreyPackPurchaseDetails = () => {
  const selectedPackType = useSelectedPackTypeDetails()
  const packs = useToreyPackDetails()
  const packDetails = packs.data!.find(
    (pack) => pack.name.toLowerCase() === selectedPackType.type.toLowerCase(),
  )
  const { currentUser } = useAuth()
  const packLimit = trpc.useQuery(
    [
      'series3.packLimit',
      {
        address: currentUser.addr,
      },
    ],
    {
      enabled: !!currentUser.loggedIn,
    },
  )
  const imageBounceAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromLeftAnimation,
    mobile: BounceInOutAnimation,
    tablet: BounceInOutAnimation,
  })
  const detailsAnimation = useDeviceSize({
    initial: null,
    desktop: SlideInFromRightAnimation,
    mobile: BounceInOutAnimation,
    tablet: SlideInFromRightAnimation,
  })

  if (imageBounceAnimation === null) return null

  return (
    <div className={cx('pack-purchase-detail-container', 'container')}>
      <motion.div
        variants={imageBounceAnimation}
        className={cx('img-container')}
      >
        <ToreyPackImage src={packDetails!.image!} type={packDetails!.name!} />
      </motion.div>

      <motion.div variants={detailsAnimation} className={cx('copy-container')}>
        <div className={cx('header')}>
          <div>
            <h3>{packDetails!.name!} Pack</h3>
            <h4>Series #3</h4>
          </div>
          <div>
            <h3>${packDetails!.price! / 100} per pack</h3>
            <span>
              {packDetails?.available} / {packDetails?.total} available
            </span>
          </div>
        </div>
        <hr />
        <div className={cx('pack-info')}>
          <h4>
            Series 3 packs contain 36 different NFT moments from one of the
            gnarliest filmed parts of all time: Torey Pudwill&#39;s part in the
            &#39;Bigger Bang&#39;.
          </h4>
          <h3>Details</h3>
          <ul>
            <li>- 3 NFT moments per pack.</li>
            <li>
              - Chance to pull the{' '}
              <strong className={cx('sweep')}>Legendary Refractor</strong> NFT
              and unlock Torey&apos;s signed & numbered tangible card.
            </li>
            <li>- Limited to 10 packs per collector.</li>
          </ul>

          <h3>Standard vs Premium&nbsp;Packs</h3>
          <ul>
            <li>
              - Odds of pulling the{' '}
              <strong className={cx('sweep')}>Legendary Refractor</strong> NFT
              is significantly higher in the premium packs.
            </li>
            <li>
              - It is not guaranteed to pull a Legendary Refractor NFT in either
              pack.
            </li>
          </ul>
        </div>

        <IfFeatureEnabled feature={FFlags.series_3_released}>
          <BuyButton />
          <strong className={cx('sale-ends-message')}>
            All pack sales will end Oct 30th at 7pm PDT
            <Countdown />
          </strong>
        </IfFeatureEnabled>
        <IfFeatureDisabled feature={FFlags.series_3_released}>
          <MailChimpCountdown />
        </IfFeatureDisabled>
      </motion.div>
    </div>
  )
}

const ToreyPackOdds = () => {
  const packDetails = useSelectedPackTypeDetails()

  if (packDetails.type === 'Premium') return <ToreyPackOddsPremium />

  return <ToreyPackOddsStandard />
}
const ToreyPackOddsStandard = () => {
  return (
    <div className={cx('packodds-container', 'container')}>
      <div className={cx('header')}>
        <h2>PACK ODDS</h2>
        <h3>Each pack includes 3 NFT moments</h3>
      </div>
      <div className={cx('grid-container', 'container')}>
        <div className={cx('nft-item')}>
          <div className={cx('img-container')}>
            <ToreyNFTThumbnail type='toreyCommon' />
          </div>
          <h3 className='commonGlow'>Common</h3>
          <h3 className='commonGlow'>2:1</h3>
        </div>
        <div className={cx('nft-item')}>
          <div className={cx('img-container')}>
            <ToreyNFTThumbnail type='toreyRare' />
          </div>
          <h3 className='rareGlow'>Rare</h3>
          <h3 className='rareGlow'>39:40</h3>
        </div>
        <div className={cx('nft-item')}>
          <div className={cx('img-container')}>
            <ToreyNFTThumbnail type='toreyLegendaryRefractor' />
          </div>
          <h3 className='sweep'>Legendary Refractor</h3>
          <h3 className='legendaryGlow'>1:40</h3>
        </div>
      </div>
    </div>
  )
}

const ToreyPackOddsPremium = () => {
  return (
    <div className={cx('packodds-container', 'container')}>
      <div className={cx('header')}>
        <h2>PACK ODDS</h2>
        <h3>Each pack includes 3 NFT moments</h3>
      </div>
      <div className={cx('grid-container', 'container')}>
        <div className={cx('nft-item')}>
          <div className={cx('img-container')}>
            <ToreyNFTThumbnail type='toreyCommon' />
          </div>
          <h3 className='commonGlow'>Common</h3>
          <h3 className='commonGlow'>2:1</h3>
        </div>
        <div className={cx('nft-item')}>
          <div className={cx('img-container')}>
            <ToreyNFTThumbnail type='toreyRare' />
          </div>
          <h3 className='rareGlow'>Rare</h3>
          <h3 className='rareGlow'>9:10</h3>
        </div>
        <div className={cx('nft-item')}>
          <div className={cx('img-container')}>
            <ToreyNFTThumbnail type='toreyLegendaryRefractor' />
          </div>
          <h3 className='sweep'>Legendary Refractor</h3>
          <h3 className='legendaryGlow'>1:10</h3>
        </div>
      </div>
    </div>
  )
}

const ToreyFAQ = () => {
  return (
    <div className={cx('faq-container', 'container')}>
      <div className={cx('question')}>
        <h2>
          What if I pull a <span className='sweep'>Legendary Refractor</span>{' '}
          NFT?
        </h2>
        <p>
          Every legendary refractor NFT will contain a signed and numbered
          tangible card that we will ship directly to your door.
        </p>
      </div>
      <div className={cx('question')}>
        <h2>How can I get the 1/1 Torey Pudwill skated deck?</h2>
        <p>
          If a &#34;Nollie flip crook&#34; NFT is pulled from a pack, you will
          automatically receive raffle tickets and be entered to win the deck
          that Torey skated in that exact moment.
        </p>
        <br></br>
        <p>
          Standard Pack: <span className={cx('RareGlow')}>Rare</span> = 2 raffle
          tickets.
          <br></br>
          Premium Pack: <span className={cx('RareGlow')}>Rare</span> = 10 raffle
          tickets.
        </p>
      </div>
      <div className={cx('question')}>
        <h2>What does the serial number&nbsp;mean?</h2>
        <p>
          Every unique moment will have a serial number. Serial numbers can not
          be duplicated, there will never be two of the same serial numbers for
          the same unique&nbsp;moment.
        </p>
      </div>
      <div className={cx('question')}>
        <h2>
          How many Common, Rare, Vaulted Rare, and Legendary moments are in the
          entire series?
        </h2>
        <p>
          20 <strong className={cx('CommonGlow')}>Common</strong>, 15{' '}
          <strong className={cx('rareGlow')}>Rare</strong>, 1{' '}
          <strong className='sweep'>Legendary Refractor</strong>.
        </p>
      </div>
    </div>
  )
}
