import { trpc } from '~/api/client'

const packDetailsDefaultData = [
  {
    name: 'Standard',
    price: 1269,
    image: '/pack-thumbnail-standard.jpg',
    available: 2000,
    sold: 0,
    total: 2000,
  },
  {
    name: 'Premium',
    price: 4969,
    image: '/pack-thumbnail-premium.jpg',
    available: 1000,
    sold: 0,
    total: 1000,
  },
] as const

export const useToreyPackDetails = () => {
  return trpc.useQuery(['series3.packTypeData'], {
    initialData: packDetailsDefaultData,
    onSuccess: defaultItems(packDetailsDefaultData),
  })
}

const boxDetailsDefaultData = [
  {
    name: 'Standard',
    price: 12690,
    image: '/Box_Standard-Blk-bg.jpeg',
    available: 200,
    sold: 0,
    total: 200,
  },
  {
    name: 'Premium',
    price: 49690,
    image: '/Box_Premium-Blk-bg.jpeg',
    available: 100,
    sold: 0,
    total: 100,
  },
] as const

export const useToreyBoxDetails = () => {
  return trpc.useQuery(['series3.boxTypeData'], {
    initialData: boxDetailsDefaultData,
    onSuccess: defaultItems(boxDetailsDefaultData),
  })
}

function defaultItems(data: any[]) {
  return function (items: any[]) {
    if (!items[0] || !items[0].available || items[0].available === 0)
      items[0] = data[0]
    if (!items[1] || !items[1].available || items[1].available === 0)
      items[1] = data[1]
  }
}
