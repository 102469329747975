import { useEffect, useState } from 'react'

export function useMediaQuery(query: string, initial = null) {
  const [matches, setMatches] = useState<boolean | typeof initial>(initial)

  useEffect(() => {
    const media = window?.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }
    if (!media.addEventListener) return
    media?.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [matches, query])

  return matches
}

export const useIsMobile = () => useMediaQuery('(max-width: 700px)')
export const useIsTablet = () => useMediaQuery('(max-width: 830px)')
export const useIsDesktop = () => useMediaQuery('(min-width: 830px)')

export function useDeviceSize<T>(props: UseDeviceSizeProps<T>) {
  const mobile = useIsMobile()
  const desktop = useIsDesktop()
  const tablet = useIsTablet()

  if (mobile) return props['mobile'] || props.initial
  if (desktop) return props['desktop'] || props.initial
  if (tablet) return props['tablet'] || props.initial

  return props.initial
}

// TODO: Greg type this :),
interface UseDeviceSizeProps<
  Initial = null,
  Desktop = any,
  Mobile = any,
  Tablet = any,
> {
  initial?: Initial
  desktop?: Desktop
  mobile?: Mobile
  tablet?: Tablet
}
