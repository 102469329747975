import { ToreyLegendaryRefractor, ToreySkatedDeck } from 'components'
import { withClassNames } from '~/styles'
import s from './torey-tangibles.module.scss'
const cx = withClassNames(s)

export const ToreyTangibles = () => {
  return (
    <div className={cx('prod-tangibles', 'container')}>
      <div>
        <h2>
          Unlock tangible collectibles<br></br>through owning NFT&#39;s
        </h2>
      </div>

      <div className={cx('tangible-items')}>
        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <ToreyLegendaryRefractor />
          </div>
          <strong className='sweep'>Legendary Refractor Card</strong>
          <p>
            Every time a Legendary Refractor NFT is pulled out of a pack, we
            will ship the tangible card to your door.
          </p>
        </div>

        <div className={cx('tangible-item')}>
          <div className={cx('media-container')}>
            <ToreySkatedDeck />
          </div>
          <h3>Deck from Torey's Bigger Bang Part</h3>
          <p>
            For every{' '}
            <strong className='rareGlow'>&quot;Nollie Flip Crook&quot;</strong>{' '}
            clip that is pulled, you will receive raffle tickets to win
            Torey&apos;s Skated Deck from that exact moment.
          </p>
        </div>
      </div>
    </div>
  )
}
