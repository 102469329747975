import Image, { ImageProps } from 'next/image'

export const ToreyPack = (
  props: {
    src: string
  } & ImageProps,
) => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <ToreyPackImage {...props} />
    </div>
  )
}

export const ToreyPackImage = ({
  src,
  ...props
}: {
  src: string
} & ImageProps) => {
  return (
    <Image
      src={src}
      layout={'responsive'}
      width={350}
      height={350}
      alt={'Torey Standard Pack Image'}
      {...props}
    />
  )
}

export const ToreyPackImageFromType = ({
  type,
  ...props
}: { type: 'Premium' | 'Standard' } & Omit<ImageProps, 'src'>) => {
  const src = `/pack-thumbnail-${type?.toLowerCase()}.jpg`

  return <ToreyPackImage src={src} {...props} />
}
