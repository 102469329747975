import { trpc } from '~/api/client'

const packDetailsDefaultData = [
    {
        name: 'Standard',
        price: 1469,
        image: '/prod-pack-standard.gif',
        sold: 0,
        available: 3000,
        total: 3000,
    },
    {
        name: 'Premium',
        price: 5969,
        image: '/prod-pack-premium.gif',
        sold: 0,
        available: 1500,
        total: 1500,
    },
] as const

export const useProdPackDetails = () => {
    return trpc.useQuery(['series2.packTypeData'], {
        initialData: packDetailsDefaultData,
        onSuccess: defaultItems(packDetailsDefaultData),
    })
}

function defaultItems(data: any[]) {
    return function (items: any[]) {
        if (!items[0] || !items[0].available || items[0].available === 0)
            items[0] = data[0]
        if (!items[1] || !items[1].available || items[1].available === 0)
            items[1] = data[1]
    }
}