import dynamic from 'next/dynamic'
import { ICountdown, countdownDate } from './CountDownBase'

export {
  useCountDownTimer,
  countdownDate,
  PleaseWait,
  AvailableText,
  AllPacksExpireBox,
  SoldOutGradientBox,
} from './CountDownBase'

const _Countdown = dynamic(
  import('./CountDownBase').then((mod) => mod.Countdown),
  { ssr: false },
)
export const Countdown = (props: Partial<ICountdown>) => {
  // @ts-ignore
  return <_Countdown countdownDate={props.countdownDate} />
}
const _CountdownTimer = dynamic(
  import('./CountDownBase').then((mod) => mod.CountdownTimer),
  { ssr: false },
)
export const CountdownTimer = (props: ICountdown) => {
  return <_CountdownTimer {...props} />
}

export const MailChimpCountdown = () => {
  const _MailChimpCountdown = dynamic(
    import('./CountDownBase').then((mod) => mod.MailChimpCountdown),
    { ssr: false },
  )

  return <_MailChimpCountdown />
}
