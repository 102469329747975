import { withClassNames } from '~/styles/utils'
import s from './packtype.module.scss'
import { PageTransitions } from '../components/Animations'
export const cx = withClassNames(s)

export const PackType = ({ children }: any) => (
  <PageTransitions>
    <div className={cx('packtype-container')}>{children}</div>
  </PageTransitions>
)
